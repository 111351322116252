<script>
import Layout from "../../layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";


export default {
  page: {
    title: "Dashboard",
    meta: [
      {
        name: "description",
        content: appConfig.description,
      },
    ],
  },
  components: {
    Layout,
    PageHeader
  },
  data() {
    return {
      title: "Dashboard",
      items: [
        {
          text: "Munshi",
        },
        {
          text: "Dashboard",
          active: true,
        },
      ],
    };
  },
  mounted() {
    if ((this.$storageData.loginType && this.$storageData.loginType == "admin")) {
      this.$router.push('/dashboard/superAdminDashboard');
    }
    else {
      this.$router.push('/landing');
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <Stat />
    <div class="row">
      <div class="col-xl-4">
        <div class="card bg-primary">
          <div class="card-body">
            <div class="row align-items-center">
              <div class="col-sm-8">
                <p class="text-white font-size-18">
                  <i class="mdi mdi-arrow-right"></i>
                </p>
                <div class="mt-4">
                </div>
              </div>
              <div class="col-sm-4">
                <div class="mt-4 mt-sm-0">
                  <div></div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card-body-->
        </div>
        <!-- end card-->
      </div>
    </div>
    <div class="row">
    </div>
  </Layout>
</template>